@import url('clash-display.css');
@import url("https://fonts.googleapis.com/css?family=Raleway:400");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --d: 2500ms;
    --angle: 90deg;
    --gradX: 100%;
    --gradY: 50%;
    --c1: rgba(74, 222, 128, 1);
    --c2: rgba(74, 222, 128, 0.1);
}

body {
    font-family: 'ClashDisplay', sans-serif;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
    min-height: 100vh;
}

/* Define custom properties and keyframes for the conic gradient effect */
@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
}

/* Define the conic gradient border effect and animation */
.border-conic-gradient {
    border: 0.1rem solid;
    border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
    animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
    100% {
        --angle: 420deg;
    }
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.currency-input {
    -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Remove default styling in most browsers */
    width: 100%;
    padding: 8px;
    padding-right: 48px; /* Adjust space for currency symbol and flag */
    background-color: #1F2937; /* Dark background */
    color: white; /* White text */
    border: 0.1rem solid #4ade80; /* Green border */
    border-radius: 8px;
}

.currency-input::-webkit-inner-spin-button,
.currency-input::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Remove default buttons */
    margin: 0; /* Remove margin */
}

.currency-symbol {
    position: absolute;
    right: 10px; /* Adjust position */
    display: flex;
    align-items: center;
    color: white;
}

.currency-symbol img {
    width: 16px; /* Adjust size of the flag */
    height: auto;
    margin-left: 4px; /* Adjust space between TL and flag */
}

